.use-custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(46, 46, 46, 0.3);
  box-shadow: inset 0 0 4px rgba(46, 46, 46, 0.3);
  background-color: #F5F5F5;
}

.use-custom-scrollbar::-webkit-scrollbar {
  width: 4px;
  background-color: #F5F5F5;
}

.use-custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #a1a1a1;
}