@tailwind base;

@layer base {
  svg {
    vertical-align: baseline;
  }
  @font-face {
    font-family: 'GilroyWebBold';
    src:
        url('../fonts/gilroy_bold.woff2') format('woff2'),
        url('../fonts/gilroy_bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
      font-family: 'GilroyWebRegular';
      src:
          url('../fonts/gilroy_regular.woff2') format('woff2'),
          url('../fonts/gilroy_regular.woff') format('woff');
      font-weight: normal;
      font-style: normal;
  }
}