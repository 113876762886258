.ant-typography-edit, .item-action {
  display: none !important;
}

.ant-table-cell-row-hover .ant-typography-edit,
.ant-table-cell-row-hover .item-action
{
  display: inline-block !important;
}

.items-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(46, 46, 46, 0.3);
  box-shadow: inset 0 0 4px rgba(46, 46, 46, 0.3);
  background-color: #F5F5F5;
}

.items-table::-webkit-scrollbar {
  width: 4px;
  background-color: #F5F5F5;
}

.items-table::-webkit-scrollbar-thumb {
  background-color: #a1a1a1;
}